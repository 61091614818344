<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="loading"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <!--      <div class="table-operator">-->
      <!--        <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>-->
      <!--      </div>-->
      <div style="display: block">
        <s-table
            ref="table"
            rowKey="ov_id"
            :loading="isLoading"
            :columns="columns"
            :data="loadData"
            :pageSize="15"
            :scroll="{ x: 'max-content' }"
        >
          <div slot="zh_loadunload_list" slot-scope="text, item">
            <div v-for="item in item.order.loadunload_list">
              {{ item.zhdz_info ? item.zhdz_info.address_name : '' }}
            </div>
          </div>
          <div slot="xh_loadunload_list" slot-scope="text, item">
            <div v-for="item in item.order.loadunload_list">{{
                item.xhdz_info ? item.xhdz_info.address_name : ''
              }}
            </div>
          </div>
          <div slot="cost_name" slot-scope="text, item">
            <a-tag v-if="item.cost_type==0" color="green">装车/磅费</a-tag>
            <a-tag v-if="item.cost_type==1" color="blue">修车/洗车费用</a-tag>
            <a-tag v-if="item.cost_type==2" color="orange">其他费用</a-tag>
            {{ text }}
          </div>

          <div slot="cost_price_type" slot-scope="text, item">
            <a-tag v-if="text==0">自行垫付</a-tag>
            <a-tag v-else-if="text==1" color="blue">公司已付</a-tag>
          </div>
          <div slot="amount" slot-scope="text, item">
            <span style="color: rgb(208, 5, 5)">￥</span>{{ text }}
          </div>

          <div slot="fymx" slot-scope="text, item">
            <template v-for="item2 in item.cost_list">
              <a-tag style="margin: 5px 0">
                {{ item2.cost_name }}：<span style="color: rgb(208, 5, 5)">￥</span>{{ item2.cost_price }} |
                {{ item2.cost_price_type == 0 ? '自行垫付' : '公司已付' }}
              </a-tag>
              <br>
            </template>
          </div>
          <div class="actions" slot="action" slot-scope="text, item">
            <a @click="handleEdit(item)">编辑</a>
            <!--            <a-popconfirm-->
            <!--                v-action:delete-->
            <!--                title="确认删除当前记录？"-->
            <!--                ok-text="确定"-->
            <!--                cancel-text="取消"-->
            <!--                @confirm="handleDelete(item)"-->
            <!--            >-->
            <!--              <a href="#">删除</a>-->
            <!--            </a-popconfirm>-->
          </div>
        </s-table>

      </div>
      <SaveForm ref="SaveForm" @handleSubmit="handleRefresh"/>
      <SaveOrderVehicle
          :orderId="0"
          ref="SaveOrderVehicle"
          @handleSubmit="handleRefresh"
      />
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/order/cost'
import {STable} from "@/components";
import SearchForm from "./modules/SearchForm";
import SaveForm from "./modules/SaveForm";
import SaveOrderVehicle from "@/views/order/model/orderVehicle/Save.vue";

export default {
  name: "OrderVehicleCostLog",
  components: {SaveOrderVehicle, SearchForm, SaveForm, STable},
  data() {
    return {
      title: '订单费用记录',
      queryParam: {},
      visible: false,
      loading: false,
      isLoading: false,
      vehicle_id: "",
      columns: [
        {
          align: 'center',
          title: '所属客户',
          dataIndex: 'order.customer.customer_name',
        },
        {
          align: 'center',
          title: '装货地址',
          dataIndex: 'zloadunload_list',
          scopedSlots: {customRender: 'zh_loadunload_list'},
        },
        {
          align: 'center',
          title: '卸货地址',
          dataIndex: 'xloadunload_list',
          scopedSlots: {customRender: 'xh_loadunload_list'},
        },
        {
          align: 'center',
          title: '调度车辆',
          dataIndex: 'vehicle_name',
        },
        {
          align: 'center',
          title: '派单时间',
          dataIndex: 'order.create_time',
        },
        {
          align: 'center',
          title: '费用明细',
          dataIndex: 'order.create_time',
          scopedSlots: {customRender: 'fymx'},
        },
        {
          fixed: 'right',
          title: '操作',
          dataIndex: 'action',
          scopedSlots: {customRender: 'action'}
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.orderList({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  methods: {
    open(item) {
      this.queryParam.oc_id = item.oc_id
      this.queryParam.cost_type = item.cost_type
      this.queryParam.vehicle_id = item.vehicle_id
      this.queryParam.curr_yearmonth = item.curr_yearmonth
      this.visible = true
    },
    cancel() {
      this.$emit('handleSubmit')
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveOrderVehicle.edit(item)
    },

    handleDelete(item) {
      Api.deleted({vmId: item['vm_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
